import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
import Video from "../../components/Video";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "616px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/dba2d5b8d1b4a7a5e4012dfcc6d87465/4ae3a/ys8splash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.14285714285714%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFAEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABSJOCEkyf/8QAGxABAQABBQAAAAAAAAAAAAAAAQIhAAMRIjH/2gAIAQEAAQUCJHTm7wvXbjyg5//EABYRAAMAAAAAAAAAAAAAAAAAABARIf/aAAgBAwEBPwFQf//EABcRAQADAAAAAAAAAAAAAAAAAAABAhH/2gAIAQIBAT8BizX/xAAcEAACAgIDAAAAAAAAAAAAAAAAEQFBECExUXL/2gAIAQEABj8Cb12OqOBRZHnH/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARITFRgZHx/9oACAEBAAE/IaEWPTibsAfKPHTWzqoG/UNo78QTwn//2gAMAwEAAgADAAAAEJA//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAEDAQE/EA6gL//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAgEBPxBF2P/EABsQAQADAQEBAQAAAAAAAAAAAAEAESFBMVFh/9oACAEBAAE/ECU9VxkPHfkCa3ShRRrL39igjWW+27G+R4nagrokOvUUIjk6Xyf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Ys 8 Title",
            "title": "Ys 8 Title",
            "src": "/static/dba2d5b8d1b4a7a5e4012dfcc6d87465/4ae3a/ys8splash.jpg",
            "srcSet": ["/static/dba2d5b8d1b4a7a5e4012dfcc6d87465/8356d/ys8splash.jpg 259w", "/static/dba2d5b8d1b4a7a5e4012dfcc6d87465/bc760/ys8splash.jpg 518w", "/static/dba2d5b8d1b4a7a5e4012dfcc6d87465/4ae3a/ys8splash.jpg 616w"],
            "sizes": "(max-width: 616px) 100vw, 616px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`After making my way through Tales of Arise, a series I've not touched since the original on the SNES, I was looking for a game in a similar vein. I happened upon the Ys series, yet another old school series I hadn't touched since the SNES days; it was Ys 3 that I had played in the past. I chose 8 because it was available on the Switch which is a platform I'd choose almost every time, if possible, since I can play it while hanging out on the couch with some TV in the background.`}</p>
    <h3>{`The Good`}</h3>
    <h4>{`The Combat`}</h4>
    <p>{`The combat is definitely the highlight of this game. Monsters can be found wandering the overworld and there's no loading to start the battle, unlike Tales. More often than not you're going to charge into that enemy with one of the many skills you unlock for the cast of six. There's a wide variety of skills to unlock for each of the six characters you'll have join you, and for the most part they all feel really good. You'll be able to use them frequently as well since just hitting monsters in the face regenerates your energy to use them. As you level up and use your current skills more, you'll unlock even more of them. Furthermore you're given access to two "counters." One of them speeds up time for you allowing you to get a bunch of unanswered attacks in and/or circle around the monster to hit weak point. The other also gives you a little extra time but more importantly makes all your hits crit for a little while. Both of these abilities completely mitigate the damage that would have been dealt to you. Your party consists of three characters at a time and you can switch between them on the fly. This let's you spread damage around your party, since I don't believe I ever saw a non-PC character take damage, or take advantage of monster weaknesses to your weapon type. `}</p>
    <h4>{`Great Exploration`}</h4>
    <p>{`This game, like many of the Ys games that have come before it (or so I've read), has the main character wrecking his ship and waking up on a deserted island. Initially you have no real method of expanding the area you can explore, but as you progress you'll unlock gloves that let you climb vines, boots that let you walk on water, a light that let's you explore dark areas, etc. It's an impressive list that grows almost right up until the very end and makes the game feel very much like Metroidvania since you can always back track to that pool of water you drowned in to see what's at the bottom. Another nicety is that you are given access, almost immediately, to unrestricted fast travel. Not only that in addition to being able to instantly teleport to any camp or crystal you've come across, later on you're given the ability to teleport to the landmarks you've slowly been discovering. This gives you another reason to get back out there and explore.`}</p>
    <h4>{`Town Development`}</h4>
    <p>{`This ties in a bit with the exploration part, but since you've woken up on a deserted island, you and your fellow castaways decide to set up camp in a secluded, easily defensible valley in the mountains. As you explore the island you'll discover more and more castaways to send back to your village. While not every villager actively contributes to the town economy, several of them open up new shops. You get a smithy, an accessory maker, a farmer, a doctor, etc. This adds a fun loop of exploration and upgrading since you'll almost always want to head back to town to see what, if anything, these people can do for you. Here's an interesting question: what would the economy of a bunch of castaways actually look like? I'd say, that even these days, the answer for a lot of games would still be money. That's not the case here, monsters and plant life drop parts, and those parts can be traded up at a stockpile in the village or exchanged for other goods and services. It all plays nicely together with the setting.`}</p>
    <h4>{`The Soundtrack Slaps`}</h4>
    <p>{`This is definitely a game I recommend you play with the sound up. The soundtrack is absolutely amazing. Take this gem for instance. It's the early overworld theme.`}</p>
    <Video videoSrcUrl='https://www.youtube.com/embed/HvBla5NUlvk' videoTitle='Ys 8 Sunshine Coastline' mdxType="Video" />
    <h3>{`The Bad...okay maybe just the not so great`}</h3>
    <h4>{`The Story`}</h4>
    <p>{`Perhaps the greatest issue this game has it's story. For almost all of the game all you're really trying to do is get off the island and honestly that makes sense. It's the games final chapter where things go sideways. Certainly the turn is hinted at by the games midpoint, but there's just not enough story telling or world building to shoulder the weight of what feels like a mostly bolted on final chapter. If the game ended with you slaying the kraken and you escaping the island, I don't think you would have missed out on too much. Complimenting the lackluster story is cast of incredibly forgettable characters. I cannot think of a single standout character. Dogi, your best bud, does have a pretty cool scene during the fight with the kraken, but since he spent the previous five chapters sitting back at base it feels a bit unearned. The side missions also add very little nuance to the castaways. They are still 100% worth doing however because you will be given great gear or permanent stat boosts once you complete them.`}</p>
    <h4>{`Attrition Based Endgame`}</h4>
    <p>{`My second big issue with the game is that once you get towards the end of chapter five (out of six), many of the bosses and several of the regular monsters become sponges. Even with maxed out weapons and skills some of these fights can take a `}<em parentName="p">{`long`}</em>{` time. Couple this with the fact that there's no true healer or healing skills and you have a problem. You are now forced to execute one of your combo skills to avoid all the damage or use a limited supply of healing items. In order for those healing items to be refreshed you have to run back to base and visit the doctor to brew some more potions. The downside here is that now you've got to fast travel back, which respawns all the monsters, and thus the cycle continues. When you're not in a dungeon, you can stand still to recover your HP, but by the end of the game even this can take two to three minutes since you have so much health to recover. The speed of the healing does increase over time, but it's never quite fast enough.`}</p>
    <h4>{`Controller Mapping Issues`}</h4>
    <p>{`This may just be a Switch related issue, but often I'd find myself switching characters accidentally because the swap character button is the same as one of your skill buttons plus the R button. If you accidentally fail to press R all the way down now you've switched characters and first you've got to realize that, then switch again twice or live with your choice. I tried remapping this but there wasn't another obvious (or unused) button to move it too, so I put it on my fishing button. The only problem here was that whenever I was close to a body of water, now its a crapshoot as to whether or not I bust out my rod or switch characters. The other issue that kept cropping up was the mini map. This is tied to L3 and during the heat of battle I often found myself pressing L3 a little too hard which brings up a map which covers about a third of the screen while in handheld mode. This too causes a bit of a scramble.`}</p>
    <h3>{`Final Thoughts`}</h3>
    <p>{`Overall, if I had to give the game a grade, it'd be a solid B. It does a lot right, and controller issues aside, had the story been better it would probably be an A. It was good enough that I'd be willing to give Ys 9 a shot if there's ever another downtime in new games that I'm dying to play. If I had to rate this against Tales of Arise though, Tales wins every time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      